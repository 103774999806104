.toast_custom {
    background-color: #041838 !important;
}

/* Animação de slide para a esquerda */
.slide-left-enter {
    transform: translateX(100%);
    opacity: 0;
}

.slide-left-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms ease, opacity 300ms ease;
}

.slide-left-exit {
    transform: translateX(0);
    opacity: 1;
}

.slide-left-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 300ms ease, opacity 300ms ease;
}
  
/* Animação de slide para a direita */
.slide-right-enter {
    transform: translateX(-100%);
    opacity: 0;
}

.slide-right-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 300ms ease, opacity 300ms ease;
}
  
.slide-right-exit {
    transform: translateX(0);
    opacity: 1;
}

.slide-right-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 300ms ease, opacity 300ms ease;
} 

.active_navbar_text {
    font-weight: bold;
}

.active_navbar {
    border-bottom: 5px solid white;
}

.Home {
    padding: 0 0 0 0 !important;
    margin: 0 0 0 0 !important;
    height: 100vh !important;
}

.div_main_conts_home {
    margin-bottom: 2rem;
}

.div_back {
    background-color: #041838 !important;
    height: auto !important;
    width: auto !important;
}

.main_navbar {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #041838 !important;
    height: 12vh !important;
    padding-left: 2% !important;
    margin-bottom: 0 !important;
}

.div_text_navbar {
    height: auto;
}

.text_navbar {
    margin-left: 80%;
    font-family: Helvetica, sans-serif;
    font-size: 1.2rem;
    color: white;
}

.div_img_logo_navbar {
    height: 8vh;
}

.img_logo_navbar {
    height: 100%;
    width: auto;
}

.sub_navbar {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #002D4C !important;
    height: 6vh !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.div_text_sub_navbar {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
    width: auto !important;
    margin-right: 5% !important;
    padding: 1% !important;
}

.div_text_sub_navbar:last-child {
    margin-right: 0 !important;
}

.div_text_sub_navbar:hover {
    background-color: #1E4B6A;
}

.text_sub_navbar {
    font-family: Helvetica, sans-serif;
    font-size: 1.2rem;
}

.main_div_home {
    width: 100%;  /* Use 100vw para a largura total da viewport */
    height: 100%; /* Use 100vh para a altura total da viewport */
    box-sizing: border-box; /* Inclui o padding e border no cálculo do width e height */
    padding: 0 8% 0 8%;
    margin-top: 2% !important;
    margin-bottom: 3% !important;
}

.div_forms {
    width: auto;
    max-height: 100%;
    overflow-y: auto ;
    overflow-x: hidden;
}

input {
    border-bottom: black !important;
    box-shadow: 0 1px 0 0 black !important;
    font-family: Helvetica, sans-serif;
}

/* Cor do círculo do rádio quando selecionado */
input[type="radio"]:checked + span::before {
    border: 2px solid #041838 !important;
    background-color: #002D4C !important;
}

/* Cor do círculo interno quando o rádio é selecionado */
input[type="radio"]:checked + span::after {
    border: 2px solid #041838 !important;
    background-color: #002D4C !important;
}

/* Cor do círculo do rádio quando não selecionado */
input[type="radio"] + span::before {
    border: 2px solid #041838 !important;
}

/* Cor do texto associado ao botão de rádio */
input[type="radio"] + span {
    color: black !important;
}

textarea {
    border-bottom: black !important;
    box-shadow: 0 1px 0 0 black !important;
    font-family: Helvetica, sans-serif;
}

label {
    color: black !important;
    font-family: Helvetica, sans-serif;
}

.text_checkbox {
    margin-top: 1rem !important;
    margin-right: 2rem !important;
    font-family: Helvetica, sans-serif !important;
    color: black !important;
}

.checkbox_custom[type="checkbox"].filled-in:checked + span:after{
    border: 2px solid #041838 !important;
    background-color: #041838 !important;
}

.checkbox_custom[type="checkbox"].filled-in + span:after{
    border: 2px solid #041838 !important;
}

.main_div_conts {
    height: auto;
    margin-top: 5% !important;
    padding: 0.1rem 2% 0.1rem 2%;
    border-radius: 5px;
}

.text_title_conts {
    font-family: Helvetica, sans-serif;
}

.div_title_conts_mobile {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.no_text_conts {
    font-family: Helvetica, sans-serif;
    margin-left: 2rem;
}

.tag_section {
    display: flex; /* Altera para flex para centralizar os itens */
    flex-wrap: wrap; /* Permite que os itens quebrem linha */
    justify-content: center; /* Centraliza os itens horizontalmente */
    align-items: center; /* Centraliza os itens verticalmente (se necessário) */
    height: auto; /* Ajusta a altura de acordo com o conteúdo */
    width: auto; /* Ajusta a largura conforme necessário */
    padding: 10px; /* Espaçamento interno */
}

.tag_item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: auto !important;
    height: auto !important;
    margin: 1rem;
    padding: 1rem;
    border-radius: 5px;
}

.text_tag {
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
}

.buttons {
    margin: 1rem;
    background-color: #041838 !important;
    font-family: Helvetica, sans-serif;
    height: auto !important;
}

.round_buttons {
    margin-top: 2rem;
    margin-right: 2rem;
    background-color: #041838 !important;
    font-family: Helvetica, sans-serif;
}

.round_buttons_user {
    margin-left: 1rem;
    background-color: #041838 !important;
    font-family: Helvetica, sans-serif;
}

.div_btn_cards {
    display: flex !important;
    justify-content: right !important;
    align-items: right !important;
    margin-left: 10rem;
}

.div_btn_cards_mobile {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 10rem;
}

.buttons_forms {
    background-color: #041838 !important;
    font-family: Helvetica, sans-serif;
    margin-bottom: 1%;
}

.input_color {
    margin-bottom: 2rem !important;
}

.input_searchbar {
    padding: 1rem;
}

.tag_dropdown_input {
    font-family: Helvetica, sans-serif;
}

.div_dropdown_input {
    margin-bottom: 2% !important;
}

.text_rosa {
    color: #CD1870;
}

.text_lilas {
    color: #855FDF;
}

.text_roxo {
    color: #585094;
}

.text_verde {
    color: #00AFA9;
}

.text_cinza {
    color: #9FA1A1;
}

.div_rosa {
    background-color: #CD1870;
}

.div_lilas {
    background-color: #855FDF;
}

.div_roxo {
    background-color: #585094;
}

.div_verde {
    background-color: #00AFA9;
}

.div_cinza {
    background-color: #9FA1A1;
}

.text_title_show_modal {
    font-family: Helvetica, sans-serif;
    margin-left: 1%;
    margin-bottom: 2%;
}

.text_subtitle_show_modal {
    font-family: Helvetica, sans-serif;
    margin-left: 2%;
    margin-bottom: 1%;
}

.text_show_modal {
    font-family: Helvetica, sans-serif;
    margin-left: 3%;
    margin-bottom: 1%;
}

.text_show_modal_sub {
    font-family: Helvetica, sans-serif;
    margin-left: 4%;
    margin-bottom: 1%;
}

.buttons_show_modal {
    background-color: #041838 !important;
    font-family: Helvetica, sans-serif;
    margin-bottom: 1%;
    margin-top: 2%;
    margin-left: 2%;
}

.buttons_add_form_test {
    background-color: #041838 !important;
    font-family: Helvetica, sans-serif;
    margin-bottom: 2%;
}

.buttons_add_form_sign {
    background-color: #041838 !important;
    font-family: Helvetica, sans-serif;
    margin-top: 2%;
    margin-bottom: 1%;
}

.text_add_title_sign {
    font-family: Helvetica, sans-serif;
    margin-bottom: 2%;
}

.user_list {
    margin-top: 3rem;
}

.user_list_item {
    margin-top: 0;
    margin-left: 1%;
    font-family: Helvetica, sans-serif;
    font-size: 1.2rem;
    width: auto;
    cursor: pointer;
}

.user_list_item:hover {
    text-decoration: underline;
    color: darkblue;
}

.div_user_list_item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-bottom: 1px solid black;
    margin-top: 0rem;
    padding-bottom: 1rem;
}

.div_user_list_item:last-child {
    border-bottom: 0px solid black;
}

.buttons_user_list {
    background-color: #041838 !important;
    font-family: Helvetica, sans-serif;
    margin-left: 1rem;
}

.text_add_sign {
    font-family: Helvetica, sans-serif;
    font-size: 1rem;
    margin-left: 1rem;
}

.text_show {
    font-family: Helvetica, sans-serif;
    font-size: 1.3rem;
    margin-left: 1%;
    margin-bottom: 1%;
}

.div_buttons_show {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 1%;
}

.buttons_show {
    margin: 1rem;
    background-color: #041838 !important;
    font-family: Helvetica, sans-serif;
    height: auto !important;
}

.div_input {
    margin-bottom: 1rem;
}

.div_input_img {
    margin-top: 2rem;
}

.div_input_radio {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

@media (max-width: 1206px) {
    .round_buttons_user:last-child {
        margin-top: 1rem;
    }
}