.ConTop {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}
  
.main_div_top {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #041838 !important;
    height: 100% !important;
    width: 100% !important;
}

.img_logo_top {
    height: auto;
    width: 100%;
    max-width: 60%;
}