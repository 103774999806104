.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Fundo semi-transparente */
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 8px solid #9FA1A1; /* Cor do fundo do loader */
    border-top: 8px solid #041838; /* Cor da borda que será animada */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin: 10rem 0 5rem 0;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.text_loader {
    font-family: Helvetica, sans-serif;
    margin-top: 0;
}