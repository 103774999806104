html, body {
  height: 100% !important;
  margin: 0 !important;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease;
}

.div_left {
  height: 100vh !important;
  margin: 0 !important;
  padding: 0 !important;
}

.div_top {
  height: 15vh !important;
  margin: 0 !important;
  padding: 0 !important;
}

.div_right {
  height: 85vh !important;
  margin: 0 !important;
  padding: 0 !important;
}