/* ConLeft.css */

.ConRight {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}
  
.main_div_right {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: white !important;
    height: 100% !important;
    width: 100% !important;
}

.inner_div_right {
    background-color: rgb(159, 159, 161) !important;
    border-radius: 8px;
    padding: 1rem;
    height: auto !important;
    width: 80% !important;
}

.form_login {
    margin-top: 5% !important;
}

input {
    border-bottom: black !important;
    box-shadow: 0 1px 0 0 black !important;
    font-family: 'Formular', sans-serif;
}

label {
    color: black !important;
    font-family: Helvetica, sans-serif;
}

.login_title {
    font-family: Helvetica, sans-serif;
    margin-bottom: 2% !important;
}

.div_btn_form_right {
    margin-top: 10% !important;
    margin-bottom: 1rem !important;
    font-family: Helvetica, sans-serif;
}

.btn_form_right {
    background-color: #041838 !important;
}